<template>
  <div class="fragment">
    <RefillProductsTableAdmin
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        :sortParams="sortParams"
        @changeSort="data => changeSort(data)"
        :navTabs="navTabs"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @changeTab="changeTab"
        @resetFilter="resetFilter"
        @reload="reload"
        @reloadTable="filter"
    />
  </div>
</template>

<script>
  import RefillProductsTableAdmin from "./RefillProductsTableAdmin/RefillProductsTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "RefillProductsTable",

    components: {
      RefillProductsTableAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getUnidentifiedPaymentsForPage,
        page: 1,
        onePage: 1,
        filterType: '',
        filterRowName: '',

        sortParams: {
          sortId: '',
          sortTransit: '',
          sortReserv: '',
          sortNormal: '',
          sortDamaged: '',
        },

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {},

        isModalRelocateGoods: false,
      }
    },

    mounted() {

      if (!this.isAdmin) {
        this.$router.push(this.$store.getters.GET_PATHS.notFound)
      }

      this.getWarehouseStorages().then(() => {
        if(this.$route.query.type !== undefined){
          this.skipRadioChecked(this.navTabs, 'active')
          this.navTabs[this.$route.query.type].active = true
        }
        this.filter()
      })
    },


    methods: {

      getSortParam() {
        this.sortParams = {
          sortId: this.$route.query.sortId || '',
          sortTransit: this.$route.query.sortTransit || '',
          sortReserv: this.$route.query.sortReserv || '',
          sortNormal: this.$route.query.sortNormal || '',
          sortDamaged: this.$route.query.sortDamaged || '',
        }
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,
          rowName: this.$route.query.rowName,
        }
      },


      async getWarehouseStorages() {
        return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          let warehouses = this.getRespPaginateData(response)
          let warehousesLocal = {}
          let activeTab = Number(this.$route.query?.warehouse) || 1

          warehouses.map((item) => {
            let warehouseItem = {}
            warehouseItem['id'] = item.id
            warehouseItem['name'] = item.id
            warehouseItem['label'] = item.name
            warehouseItem['warehouse_id'] = item.warehouse_id
            warehouseItem['active'] = activeTab === item.id
            warehousesLocal[item.id] = this._.clone(warehouseItem)
          })

          this.navTabs = warehousesLocal
        })
      },

      refreshTable() {
        this.filter()
      },

      filter(next = false) {
        this.getFilterParam()
        this.getSortParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextRefillProductsPage', true)

        // save filter params in store
        this.$store.commit('setRefillProductsFilter', window.location.search)

        this.$store.dispatch('fetchRefillProducts', url).then(() => {
          // skip get next page
          this.$store.commit('setNextRefillProductsPage', false)
        })

        this.checkCountFilter(['userName', 'type'])
      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        // myQuery.includes('itemsInStorage')

        this.generateFilterQueryParams(
          myQuery,
          {
            filterType: 'byStorageId',
            filterRowName: 'byRowId',
          },
        )

        // If generate on export file
        if(exportIds) {
          myQuery.whereIn('ProductId', exportIds)
        }

        this.generateSortQueryParams(
            myQuery,
            {
              sortId: 'ProductId',
              sortTransit: 'ProductVirtualStorageItemTransit',
              sortReserv: 'ProductVirtualStorageItemReserv',
              sortNormal: 'ProductInStorageNormal',
              sortDamaged: 'ProductInStorageDamaged',
            }
        )

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }


  }
</script>

<style scoped>

</style>
