<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['refillProducts_rowName'])"></span>
        <DefaultInput
                :label="$t('refillProducts_rowName.localization_value.value')"
                :type="'text'"
                v-model="rowName"
        />
      </div>

    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

  export default {
    name: "RefillProductsFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        rowName: this.filterGetParams.rowName ? this.filterGetParams.rowName : '',

        filterCounts: [
          'rowName',
        ],

      }
    },

    watch: {
      filterGetParams(newVal) {
        this.rowName = newVal.rowName ? newVal.rowName : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

    },
  }
</script>

<style scoped>

</style>
