<template>
  <div>
    <RefillProductsHead
            :showFilter="showFilter"
            :selectedNow="selectedNow"
            :countFilterParams="countFilterParams"
            :navTabs="navTabs"
            @selectAll="selectAll"
            @toggleFilter="toggleFilter"
            @filter="$emit('filter')"
            @changeFilter="changeFilter"
            @changeTab="(data) => $emit('changeTab', data)"
            @reloadTable="$emit('reloadTable')"
            @massThermalPrint="$emit('massThermalPrint')"
            @goodsRelocation="$emit('goodsRelocation')"
    />

    <div class="table-filter-wrap">

      <RefillProductsFilter
              :showFilter="showFilter"
              :filterGetParams="filterGetParams"
              @close="closeFilter"
              @changeFilter="changeFilter"
              @resetFilter="$emit('resetFilter')"
      />

      <RefillProductsTable
              :sortParams="sortParams"
              :selectedNow="selectedNow"
              :countFilterParams="countFilterParams"
              :navTabs="navTabs"
              @changeSort="data => $emit('changeSort', data)"
              @showMore="$emit('showMore')"
              @resetFilter="$emit('resetFilter')"
              @downloadFiles="type => $emit('downloadFiles', type)"
              @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import RefillProductsHead from "./RefillProductsHead/RefillProductsHead";
  import RefillProductsTable from "./RefillProductsTable/RefillProductsTable";
  import RefillProductsFilter from "./RefillProductsFilter/RefillProductsFilter";

  export default {
    name: "RefillProductsTableAdmin",

    components: {
      RefillProductsHead,
      RefillProductsTable,
      RefillProductsFilter,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      sortParams: Object,
      navTabs: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.filterGetParams = newVal
      },
      countFilterParams(newVal) {
        this.countFilterParams = newVal
      },
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>


</style>
