<template>
  <div class="fragment">
    <ProductForCellPopup
        v-if="isModalProductForCellPopup"
        :orderType="'inbound'"
        :user="{userId: product.user.id}"
        :productForCellItem="moveProductCell.data.productsForCellArray[0]"
        :productsForCellArray="moveProductCell.data.productsForCellArray"
        :arrayIndex="0"
        :disabledProformProduct="true"
        :popupHeaderName="'common_moveProduct'"
        :showProductCounters="false"
        :typeMoveProductCell="true"
        :allowScan="true"
        :scanToCellId="moveCellId"
        @close="() => {isModalProductForCellPopup = false; $emit('close')}"
        @moveProductCell="moveProductCellFunc"
    />
  </div>
</template>

<script>
import ProductForCellPopup from "@/components/coreComponents/Popups/ProductForCellPopup/ProductForCellPopup";
import {MoveProductCell} from "@/components/modules/ProductsModule/models/MoveProductCell";
import {ProductCells} from "@/components/globalModels/ProductCells";

export default {
  name: "MoveProductCell",
  components: {ProductForCellPopup},

  props: {
    product: Object,
    moveCellId: Number,
    navTabs: Object,
  },

  data() {
    return {
      isModalProductForCellPopup: false,

      moveProductCell: new MoveProductCell(),
    }
  },

  mounted() {
    this.getProductsByUserId()
  },

  methods: {

    moveProductCellFunc() {
      let storageId = this._.find(this.navTabs, {active: true}).name
      let data = this.moveProductCell.productsDataGeneration(this.product.user.id, storageId)

      this.$store.dispatch('createStorageDocuments', data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            setTimeout(() => {
              this.$emit('moveSuccess')
            },1000)
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    async getProductsByUserId() {
      const query = new this.Query()
      let url = '?'
      let myQuery = query
          .for('posts')
      let storageId = this._.find(this.navTabs, {active: true}).name

      // myQuery.where('ProductUserId', this.product.user.id)
      myQuery.where('ProductId', this.product.id)
      myQuery.where('byStorageId', storageId)
      myQuery.includes('itemsInStorage')

      url = url + myQuery.limit(1).page(parseInt(0)).url().split('?')[1]

      url = url + `&user_id=${this.product.user.id}`

      let fundedProduct
      let productForCell
      let otherUserCells = []
      let freeMixedCells = []
      let freeCells = []

      await this.$store.dispatch('fetchProducts', url).then((response) => {
        this.optionsProducts = this.getRespPaginateData(response)

        if(this.optionsProducts.length === 0) return

        fundedProduct = this._.first(this.optionsProducts)
        productForCell = this._.first(this.moveProductCell.data.productsForCellArray)

        productForCell.data.ProformProduct.setProductObject(fundedProduct)

      }).catch(error => this.createErrorLog(error))


      await this.$store.dispatch('getWarehouseStorageCellWithFilterPublic',
          `?filter[product_user_id]=${this.product.user.id}&filter[byStorageId]=${storageId}&limit=10000`)
          .then(response => {
        otherUserCells = this.getRespPaginateData(response);
      })

      // await this.$store.dispatch('getWarehouseStorageCellWithFilter', `?filter[mixed]=1`).then(response => {
      //   freeMixedCells = this.getRespPaginateData(response);
      // })

      await this.$store.dispatch('getWarehouseStorageCellWithFilterPublic',
          `?filter[isMixedWithoutThisUserId]=${this.product.user.id}&filter[byStorageId]=${storageId}&limit=10000`)
          .then(response => {
        freeMixedCells = this.getRespPaginateData(response);
      })

      await this.$store.dispatch('getWarehouseStorageCellWithFilterPublic',
          `?filter[isFree]=1&filter[mixed]=0&filter[byStorageId]=${storageId}&limit=10000`)
          .then(response => {
        freeCells = this.getRespPaginateData(response);
      })

      this.setCellsInProductForCell(fundedProduct, productForCell, otherUserCells, freeMixedCells, freeCells)

      this.isModalProductForCellPopup = true
    },



    setCellsInProductForCell(val, productForCell, otherUserCells, freeMixedCells, freeCells){
      let userProductCells = []

      productForCell.data.UserMixedProductCellArray = []
      productForCell.data.UserProductCellArray = []

      val.items_in_storage.map(itemInStorage => {
        let productCells = new ProductCells(false, itemInStorage.cell, itemInStorage.quantity, itemInStorage.quantity)

        if(!itemInStorage.cell && itemInStorage.cell_id) return
        if(itemInStorage.damage) return

        if(!itemInStorage.cell && !itemInStorage.damage) {
          productForCell.data.withoutCellDeclaredCounts = parseInt(productForCell.data.withoutCellDeclaredCounts) + parseInt(itemInStorage.quantity)
          productForCell.data.withoutCellEndCounts = productForCell.data.withoutCellDeclaredCounts
          return
        }

        if(itemInStorage?.cell?.mixed){
          productForCell.data.UserMixedProductCellArray.push(productCells)
        } else {
          productForCell.data.UserProductCellArray.push(productCells)
        }

        userProductCells.push(productCells)
      })

      let userCells = [...this.addOtherUserCells(otherUserCells, true, userProductCells)]
      userCells.map(userCell => {
        productForCell.data.UserCellArray.push(userCell)
      })

      productForCell.data.MixedCellArray = [...this.addOtherCells(freeMixedCells)]
      productForCell.data.FreeCellArray = [...this.addOtherCells(freeCells)]


      /**
       * sort by cell count
       */
      let userArray = productForCell.data.UserProductCellArray
      let userMixedProductCellArray = productForCell.data.UserMixedProductCellArray

      productForCell.data.UserProductCellArray = this.sortCellArrayByStorageCount(userArray)
      productForCell.data.UserMixedProductCellArray = this.sortCellArrayByStorageCount(userMixedProductCellArray)

    },

    addOtherUserCells(otherUserCells, existCell, existingCells) {
      /**
       * Добавляем остальные ячейки юзера
       */
      let cells = []

      otherUserCells.map(userCell => {

        let findExistCell = this._.find(existingCells, function (findItem) {
          return findItem.data.cellSelected?.id === userCell.id
        })
        // If exist -> next step
        if(findExistCell) {
          return
        }

        let cell = new ProductCells(otherUserCells, userCell, false, false)

        cells.push(cell)
      })

      return cells
    },

    addOtherCells(cellsArray) {
      /**
       * Добавляем пустые ячейки
       */
      let cells = []

      cellsArray.map(freeCell => {
        let cell = new ProductCells(cellsArray, freeCell, false, false)

        cells.push(cell)
      })

      return cells
    },

    sortCellArrayByStorageCount(userCellArray) {
      return this._.sortBy(userCellArray, function (item) {
        return 0 + item.data.storageCount
      })
    },

  },

}
</script>

<style scoped>

</style>