<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.product_full_name }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('refillProducts_binWhereEnded.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.cell_combo_name}}
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('refillProducts_binWhereHas.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div :key="cellIndex" v-for="(cellItem, cellIndex) in item.available_products">{{cellItem}}
                <template v-if="item.available_products.length !== cellIndex + 1">,</template></div>
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <div class="table-card__item-btn-i">
            <LinkButton
                :value="$t('product_SendTo.localization_value.value')"
                :type="'sendTo'"
                @click.native="$emit('moveProductCell')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  export default {
    name: "RefillProductsTableMobile",
    components: { LinkButton},

    props: {
      item: Object,
      getStorageId: Number,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {
      getItemsWithCells(storage) {
        let array = []
        storage.map(item => {
          if(item.cell)
            array.push(item)
        })
        return array
      },
    },

  }
</script>

<style scoped>

</style>
